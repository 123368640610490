<template>
  <div class="licencesReport">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(licences)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(licences)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="typeLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <router-link
              :to="`/installation/${ props.dataItem.installationId }/software-components/${props.dataItem.id}`"
              class="alt-primary-color"
              target="_blank"
            >
              {{ kgm_getNestedValue(props.field, props.dataItem) }}
            </router-link>
          </td>
        </template>
        <div
          slot="installationFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="installationFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Installation"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('installationFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="licenceFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="licenceFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Licence"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('licenceFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "LicenceReport",
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: false,
      licences: null,
      kgm_columns: [
        {
          field: 'installationName',
          filterable: true,
          filter: 'text',
          title: 'Installation',
          filterCell: 'installationFilter',
          cell: 'typeLink'
        },
        {
          field: 'licenceName',
          filterable: true,
          filter: 'text',
          title: this.$t('softwareComponent.licenceName'),
          filterCell: 'licenceFilter'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: this.$t('softwareComponent.licencesReport')
    }
  },
  created () {
    this.getLicencesReport();
  },
  methods: {
    //api
    getLicencesReport () {
      this.loading = true;
      this.axios.get('/SoftwareComponent/GetLicenceReport')
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.licences = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
<style>
.licencesReport .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>